import { useAuthUserStore } from '~/stores/auth/auth-user'

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useAuthUserStore()
  if (to.params.id) {
    localStorage.setItem('documentId', `${to.params.id}`)
  }

  // if (!store.userAuthToken) {
  //   let user = null
  //   if (to.query.token) {
  //     try {
  //       const data = JSON.parse(atob(to.query.token.split('.')[1]))
  //       user = data.linkData
  //     } catch (e) {}
  //   }
  //   if (user) {
  //     return navigateTo({ path: `/signer/create_password`, query: user })
  //   }
  //   return navigateTo({
  //     path: `/signer/create_account`,
  //     query: {
  //       documentId: to.params.id,
  //     },
  //   })
  // }
})
